@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato/Lato-Regular.ttf');
}

@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato/Lato-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'Spectral';
    src: url('./assets/fonts/Spectral/Spectral-Regular.ttf');
}
  
@font-face {
    font-family: 'Spectral';
    src: url('./assets/fonts/Spectral/Spectral-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'Spectral';
    src: url('./assets/fonts/Spectral/Spectral-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}
